<template>
  <div > 
    <div v-if="isLoading" class="loading-spinner">
      <div class="main" v-if=" show.corpDetails == '' || show.corpDetails == null || show.corpDetails == undefined"> 
        <div class="text-white">NO COMPANY DETAILS</div>
    </div>

    </div>
   
	  <div class="main" v-if="show.corpDetails != null || show.corpDetails != undefined || show.corpDetails != ''">
      <div class="d-flex justify-content-between"> 
        <fieldset>
              <button class="btn btn-dark" v-if="show.profile.verificationStatus == 'INPROCESS'">INPROCESS</button>
              <button class="btn btn-warning" v-if="show.profile.verificationStatus == 'UNVERIFIED'">UNVERIFIED</button>
              <button class="btn btn-success" v-if="show.profile.verificationStatus == 'VERIFIED'">VERIFIED</button>
              <button class="btn btn-dark " @click="toggle" v-if="show.profile.status == 'ACTIVE'" v-b-tooltip.hover title="Click to disable the account.">{{show.profile.status}}</button>
              <button class="btn btn-danger " @click="toggle" v-if="show.profile.status == 'CLOSED'" v-b-tooltip.hover title="Click to enable the account.">{{show.profile.status}}</button>
      </fieldset>
      
      <fieldset>
        <button class="btn btn-success right" @click="verify" v-if="show.profile.extendedInfoStatus == 'COMPLETED' && show.profile.verificationStatus != 'VERIFIED'" v-b-tooltip.hover title="Click to verify the account. Please verify only when a license has been issued.">VERIFY ACCOUNT</button>
      </fieldset>
      </div>
     

      <div class="market-content"> 
        <span style="color: red;" v-if="errors.profile.show">{{ errors.profile.msg }}</span>
        <table>
          <thead>
            <tr> 
              <th style="text-align: center !important;">Application Status: 
            </th>
            </tr>
          </thead>
          <tbody v-if="isLoading" class="loading-spinner"></tbody>
          <tbody v-if="!isLoading"> 
            <div class="d-flex coins">
                  <ul class="d-flex coins" v-for="token in init.tokens" :key="token.id"> 
                    <li class="approved-circle" v-b-tooltip.hover title="Click to verify the step on the blockchain"> 
                      <a :href="'https://explorer.blxq.org/tx/' + token.hash" target="_blank" class="approved-circle"></a>
                    </li>
                  </ul> 
            </div>
          </tbody>
        </table>
        <table>
          <thead>
            <tr> 
              <th>License Type:
                <span  >
                  <button class="btn btn-link" disabled v-if="unverified">Cannot update. Client has not completed KYC.</button>
                 <button class="btn btn-link" @click="updateLicenseType" v-if="!isSubmittingLicenseType && !isEditingLicenseType && !unverified" v-b-tooltip.hover title="Click to update the license type">update</button>
                 <button class="btn btn-link" @click="cancel" v-if="isEditingLicenseType">cancel</button>
              </span>
              </th>
              <th>License Status:
                <span  >
                  <button class="btn btn-link" disabled v-if="unverified">Cannot update. Client has not completed KYC.</button>
                 <button class="btn btn-link" @click="updateLicenseStat" v-if="!isSubmittingLicenseStat && !isEditingLicenseStat && !unverified" v-b-tooltip.hover title="Click to update the license status">update</button>
                 <button class="btn btn-link" @click="cancel" v-if="isEditingLicenseStat">cancel</button>
              </span>
              </th>
              
              <th>Risk Level (nature of business): </th>
              <th>Risk Level (Enterprise Risk): 
                <span  >
                  <button class="btn btn-link" disabled v-if="unverified">Cannot update. Client has not completed KYC.</button>
                 <button class="btn btn-link" @click="updateRiskLevel" v-if="!isSubmittingRiskLevel && !isEditingRiskLevel && !unverified" v-b-tooltip.hover title="Click to update the risk level by the compliance team">update</button>
                 <button class="btn btn-link" @click="cancel" v-if="isEditingRiskLevel">cancel</button>
              </span>
            </th>
          </tr> 
          </thead>
          <tbody v-if="isLoading" class="loading-spinner"></tbody>
          <tbody v-if="!isLoading"> 
              
                <td v-if="!isEditingLicenseType && show.profile.signupHost == 'fintech.ceza.org'"><button class="btn btn-dark">Fintech - {{ show.corpDetails.corpInfo.businessNature }}</button> </td>
                <td v-if="!isEditingLicenseType && show.profile.signupHost == 'gaming.ceza.org'"><button class="btn btn-dark">Gaming - {{ show.corpDetails.corpInfo.businessNature }}</button> </td>
            
                <td v-if="isEditingLicenseType && show.profile.signupHost == 'fintech.ceza.org'" >
                <select class="form-control" v-model="show.corpDetails.corpInfo.businessNature" @change="changeLicenseType($event)">HIGH
                      <option value="" disabled>Select an option</option>
                      <option value="Cryptocurrency Exchange">Cryptocurrency Exchange</option>
                      <option value="Operator of a Payment System">Operator of a Payment System</option>
                      <option value="Electronic Money Issuer">Electronic Money Issuer</option>
                      <option value="Digital Asset Token Offering (DATO)">Digital Asset Token Offering (DATO)</option>
                </select>
                  <span> <button class="btn btn-link" @click="submitLicenseType()" v-if="isEditingLicenseType">Submit</button></span>
                </td>
                <td v-if="isEditingLicenseType && show.profile.signupHost == 'gaming.ceza.org'" >
                <select class="form-control" v-model="show.corpDetails.corpInfo.businessNature" @change="changeLicenseType($event)">HIGH
                      <option value="" disabled>Select an option</option>
                      <option value="eCASINO">e-CASINO</option>
                      <option value="Sports Betting">Sports Betting</option>
                      <option value="Random Number Games">Random Number Games</option>
                </select>
                  <span> <button class="btn btn-link" @click="submitLicenseStat()" v-if="isEditingLicenseType">Submit</button></span>
                </td>
                <td v-if="!isEditingLicenseStat && show.profile.licenseStatus == null">not updated</td>
                <td v-if="!isEditingLicenseStat && show.profile.licenseStatus == 'new'"><button class="btn btn-dark">New Licensee</button></td>
                <td v-if="!isEditingLicenseStat && show.profile.licenseStatus == 'cancelled'"><button class="btn btn-dark">Cancelled License</button></td>
                <td v-if="!isEditingLicenseStat && show.profile.licenseStatus == 'motionFiled'"><button class="btn btn-dark">Filed a motion for reconsider</button></td>
                <td v-if="!isEditingLicenseStat && show.profile.licenseStatus == 'existing'"><button class="btn btn-dark">Existing Licensee</button></td>
                <td v-if="!isEditingLicenseStat && show.profile.licenseStatus == 'forRenewal'"><button class="btn btn-dark">For Renewal</button></td>
              <div v-if="isEditingLicenseStat">
                <td v-if="!unverified">
                <select class="form-control" v-model="show.profile.licenseStatus" @change="changeLicenseStat($event)">HIGH
                      <option value="" disabled>Select an option</option>
                      <option value="new">New Licensee</option>
                      <option value="cancelled">Cancelled License</option>
                      <option value="motionFiled">Filed a motion for reconsider</option>
                      <option  value ="existing">Existing Licensee</option>
                      <option value="forRenewal">For Renewal</option>
                  </select>
                  <span> <button class="btn btn-link" @click="submitLicenseStat()" v-if="isEditingLicenseStat">Submit</button></span>
                  
                </td>
              </div>
              
                <td v-if="show.profile.signupHost == 'fintech.ceza.org' && show.corpDetails.corpInfo.businessNature === 'Cryptocurrency Exchange'"> <button class="btn btn-danger"  >HIGH</button></td>
                <td v-if="show.profile.signupHost == 'fintech.ceza.org' && show.corpDetails.corpInfo.businessNature === 'Operator of a Payment System'"><button class="btn btn-warning"  >MEDIUM</button></td>
                <td v-if="show.profile.signupHost == 'fintech.ceza.org' && show.corpDetails.corpInfo.businessNature === 'Digital Asset Token Offering (DATO)'"><button class="btn btn-danger"  >HIGH</button></td>
                <td v-if="show.profile.signupHost == 'fintech.ceza.org' && show.corpDetails.corpInfo.businessNature === 'Electronic Money Issuer'" ><button class="btn btn-warning" >MEDIUM</button></td>
                <td v-if="show.profile.signupHost == 'fintech.ceza.org' && show.corpDetails.corpInfo.businessNature == 'null'" ><button class="btn btn-dark" >NULL</button></td>
                <td v-if="show.profile.signupHost == 'gaming.ceza.org'" > <button class="btn btn-danger" >HIGH</button></td>
           

                <td v-if="!isEditingRiskLevel && show.profile.riskLevel == null">not updated</td>
                <td v-if="!isEditingRiskLevel && show.profile.riskLevel == 'NONE'"><button class="btn btn-info">NO RISK</button></td>
                <td v-if="!isEditingRiskLevel && show.profile.riskLevel == 'LOW'"><button class="btn btn-info">LOW</button></td>
                <td v-if="!isEditingRiskLevel && show.profile.riskLevel == 'MEDIUM'"><button class="btn btn-warning">MEDIUM</button></td>
                <td v-if="!isEditingRiskLevel && show.profile.riskLevel == 'HIGH'"><button class="btn btn-danger">HIGH</button></td>
            
              <div v-if="isEditingRiskLevel">
                <td v-if="!unverified && show.profile.signupHost == 'fintech.ceza.org'">
                <select class="form-control" v-model="riskLevel" @change="changeRiskLevel($event)">HIGH
                  <option value="" disabled>Select an option for Enterprise Risk Level</option>
                      <option value="HIGH">HIGH</option>
                      <option value="MEDIUM">MEDIUM</option>
                      <option value="LOW">LOW</option>
                      <option value="NONE">NO RISK</option>
                      
                  </select>
                  <span> <button class="btn btn-link" @click="submitRiskLevel()" v-if="isEditingRiskLevel">Submit</button></span>
                </td>
                <td v-if="!unverified && show.profile.signupHost == 'gaming.ceza.org'">
                  Cannot update RISK. GAMING is automatically a HIGH RISK.
                </td>
              </div>
          </tbody>
        </table>
   
        <table class="table">
          <thead>
            <tr><th>Provisional License Issued - Expiration Date:</th>
            <th>Full License Issued - Expiration Date:</th></tr>            
          </thead>
          <tbody>
           <tr >
            <td v-if="provisionalNft.length == 0">not issued</td>
            <td v-else v-for="item in provisionalNft" :key="item.id">{{ formatDate(item.updateDate) }} - {{ formatDate(item.expirationDate) }}<span v-if="item.expiring == true"><img :src="require('../assets/ceza icons/alert.png')" style="filter: invert(50%) !important;" @click="notify()"/></span></td>
        
            <td v-if="fullLicenseNft.length == 0">not issued</td>
            <td v-else v-for="item in fullLicenseNft" :key="item.id">{{ formatDate(item.updateDate) }} - {{ formatDate(item.expirationDate) }} <span v-if="item.expiring == true"><img :src="require('../assets/ceza icons/alert.png')" style="filter: invert(50%) !important;" @click="notify()"/></span></td>
           </tr>
          </tbody>
        </table>
      </div>
      <div v-if="isLoading" class="loading-spinner"></div>
            <div class="market-content" v-if="!isLoading">
             
              <table class="table">
                  <thead>
                    <tr>
                      <th>
                        Authorized Representative:
                      </th>
                      <th>
                       Contact Details:
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <td> {{show.profile.fullName}}</td>
                    <td v-if="show.profile.mobilePhone != null">{{show.profile.mobilePhone}}</td>
                     <td v-else>{{show.profile.emailAddress}}</td>
                  </tbody>
                </table>
                <table class="table" v-if="show.corpDetails != ''">
                  <thead>
                    <tr>
                      <th>
                        Business Name:
                      </th>
                      <th>
                       Business Type:
                      </th>
                      <th>
                       Issuing Country:
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <td v-if="show.corpDetails.corpInfo != null"> {{show.corpDetails.corpInfo.businessName}}</td>
                    <td v-if="show.corpDetails.corpInfo != null">{{show.corpDetails.corpInfo.businessType}}</td>
                    <td v-if="show.corpDetails.corpInfo != null">{{show.corpDetails.corpInfo.issuanceCountry}}</td>
                  </tbody>
                </table>
            </div>
            
	</div>
  <Confirm v-if="showConfirm" @close="toggle"></Confirm>
  </div>
 
          
</template>
        
<script>
import Confirm from '../components/modal/Confirm.vue'
import moment from 'moment';
 
 
  export default {
      name: 'Header',
      components: {
        Confirm
         
      },
      
       data: function() {
      return { 
        newItem: "",
        isLoading: false,
        items: [],
        isEmail: false,
          isMobile: false,
          isSubmittingRiskLevel: false,
          isEditingRiskLevel: false,
          isEditingLicenseStat: false,
          isSubmittingLicenseStat: false,
          isEditingLicenseType: false,
          isSubmittingLicenseType: false,
          errorMsg:'',
          showConfirm:false,
          showCountryForNationality: false,
          showCountryForAddress: false,
          ncountry:'PH',
          acountry:'PH',
          riskLevel:'NONE',
          errors: {
            profile: {
                msg: "",
                show: false
            }
          },
          
          showCountry: false,
          selected: 'mobile',
          country: 'PH',
          mobileCode: '63',
          hideOrShow: "Show",
          list: {
              mobileCodes: []
          },
          isError: false,
          idTypes:[],
          isApproved: false,
          isLoiSubmitted: false,
          selectedPoid: '',
          showUpload: false,
          uploaded: false,
          selected:'',
          isBusPlanSubmitted: false,
          poidKey: '',
          desc:'',
          poids:[],
          isPaid: false,
          isApplicationComplete: false,
          isDueDiligence: false,
          isProvLicense: false,
          isIntegration: false,
          isLicensed: false,
          filename:'',
          isApprovedStep1: false,
          isApprovedStep2: false,
          isApprovedStep3: false,
          isApprovedStep4: false,
          isApprovedStep5: false,
          isApprovedStep6: false,
          isApprovedStep7: false,
          isApprovedStep8: false,
          loiFile: '',
          busPlanFile:'',
          imgUpload:'',
          showUploadBPlan: false,
          businessFileIdBplan:'',
          businessFileIdLoi:'',
          isLoiApproved: false,
          isBplanApproved: false,
          status: false,
          tokens:[],
          unverified: false,
          provisionalStep: 6,
          fullStep: 8
          
         
          
      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        provisionalNft(){
          return this.init.tokens.filter(data => data.step === this.provisionalStep)
        },
        fullLicenseNft(){
          return this.init.tokens.filter(data => data.step === this.fullStep)
        },
        fullLicense(){
          return this.init.list.documentData.filter(data => data.fileType === 'FULL-LICENSE')
        },
        provisionalOnly(){
          return this.init.list.documentData.filter(data => data.fileType === 'PROVISIONAL-LICENSE')
        },
      },
      methods:{
        cancel(){
          window.location.reload();
        },
        changeRiskLevel(event){
            this.show.profile.riskLevel = event.target.value;
        },
        changeLicenseStat(event){
            this.show.profile.licenseStatus = event.target.value;
        },
        changeLicenseType(event){
            this.show.corpDetails.corpInfo.businessNature = event.target.value;
        },
        verify(){
          const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/verifyProfile',
              }
              this.axios(options)
              .then((response) => {
                window.location.reload();
             
              }).catch((err) => {
                  
              })
        },
        enterProviDate(){

        },
        enterFullDate(){
          
        },
        updateStatus(){

        },
        toggle(){
          this.showConfirm = !this.showConfirm
         
        },
        updateRiskLevel(){
          
          if(this.show.profile.verificationStatus == 'UNVERIFIED'){
            this.unverified = true
            setTimeout(()=>{
              this.unverified = false
             }, 2000)
          } else {
            if(!this.isEditingRiskLevel) {
            this.isEditingRiskLevel = true;
            } else {
            this.isSubmittingRiskLevel = true;
            }
          }
        },
        updateLicenseStat(){
          
          if(this.show.profile.verificationStatus == 'UNVERIFIED'){
            this.unverified = true
            setTimeout(()=>{
              this.unverified = false
             }, 2000)
          } else {
            if(!this.isEditingLicenseStat) {
            this.isEditingLicenseStat = true;
            } else {
            this.isSubmittingLicenseStat = true;
            }
          }
        },
        updateLicenseType(){
          
          if(this.show.profile.verificationStatus == 'UNVERIFIED'){
            this.unverified = true
            setTimeout(()=>{
              this.unverified = false
             }, 2000)
          } else {
            if(!this.isEditingLicenseType) {
            this.isEditingLicenseType = true;
            } else {
            this.isSubmittingLicenseType = true;
            }
          }
        },
        submitRiskLevel(){
          this.isSubmittingRiskLevel = true
              const params = {
                entityId: this.$route.params.entityId,
                  nationality: this.show.profile.nationality,
                  placeOfBirth: this.show.profile.placeOfBirth,
                  occupation: this.show.profile.occupation,
                  natureOfWork: this.show.profile.natureOfWork,
                  identityNumber: this.show.profile.identityNumber,
                  mothersMaidenName: this.show.profile.mothersMaidenName,
                  maritalStatus: this.show.profile.maritalStatus,
                  employer: this.show.profile.employer,
                  sourceOfFunds: this.show.profile.sourceOfFunds,
                  grossMonthlyIncome: this.show.profile.grossMonthlyIncome,
                  riskLevel: this.riskLevel,
                  licenseStatus: this.show.profile.licenseStatus,
                  principalLicensee: this.show.profile.principalLicensee



              }

              const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/ax/updateCustomerExtendedInfo'
                          };
                  this.axios(options)
                      .then((response) => { 
                    
                          this.isSubmittingRiskLevel = false;
                          this.isEditingRiskLevel = false; 
                          this.errors.profile.show = true;
                          setTimeout(()=>{
                              this.errors.profile.show = false;
                              this.errors.profile.msg = "";
                            }, 2000)

                      }).catch((err) => { 
                          this.isSubmittingRiskLevel = true;
                          this.isEditingRiskLevel = true; 
                          if(err.response.data.fieldErrors){
                            this.errors.profile.show = true;
                            this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage
                            setTimeout(()=>{
                            this.errors.profile.show = false;
                            this.errors.profile.msg = "";
                          }, 2000)
                        }
                        else {
                            this.errors.profile.show = true;
                            this.errors.profile.msg = err.response.data.msgText
                            setTimeout(()=>{
                            this.errors.profile.show = false;
                            this.errors.profile.msg = "";
                            // window.location.reload();
                          }, 2000)
                          }

                  })
              
          
          
        },
        submitLicenseStat(){
          this.isSubmittingLicenseStat = true
              const params = {
                entityId: this.$route.params.entityId,
                  nationality: this.show.profile.nationality,
                  placeOfBirth: this.show.profile.placeOfBirth,
                  occupation: this.show.profile.occupation,
                  natureOfWork: this.show.profile.natureOfWork,
                  identityNumber: this.show.profile.identityNumber,
                  mothersMaidenName: this.show.profile.mothersMaidenName,
                  maritalStatus: this.show.profile.maritalStatus,
                  employer: this.show.profile.employer,
                  sourceOfFunds: this.show.profile.sourceOfFunds,
                  grossMonthlyIncome: this.show.profile.grossMonthlyIncome,
                  riskLevel: this.riskLevel,
                  licenseStatus: this.show.profile.licenseStatus,
                  principalLicensee: this.show.profile.principalLicensee



              }

              const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/ax/updateCustomerExtendedInfo'
                          };
                  this.axios(options)
                      .then((response) => { 
            
                          this.isSubmittingLicenseStat = false;
                          this.isEditingLicenseStat = false; 
                          this.errors.profile.show = true;
                          setTimeout(()=>{
                              this.errors.profile.show = false;
                              this.errors.profile.msg = "";
                            }, 2000)

                      }).catch((err) => { 
                          this.isSubmittingLicenseStat = true;
                          this.isEditingLicenseStat = true; 
                          if(err.response.data.fieldErrors){
                            this.errors.profile.show = true;
                            this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage
                            setTimeout(()=>{
                            this.errors.profile.show = false;
                            this.errors.profile.msg = "";
                          }, 2000)
                        }
                        else {
                        
                            this.errors.profile.show = true;
                            this.errors.profile.msg = err.response.data.msgText
                            setTimeout(()=>{
                            this.errors.profile.show = false;
                            this.errors.profile.msg = "";
                            // window.location.reload();
                          }, 2000)
                          }

                  })
              
          
          
        },
       
        submitLicenseType(){
          this.isSubmittingLicenseType = true
              const params = {
                companyEmailAddress: this.show.corpDetails.corpInfo.companyEmailAddress,
                businessPhoneNumber:  this.show.corpDetails.corpInfo.businessPhoneNumber,
                companyWebsite:  this.show.corpDetails.corpInfo.companyWebsite,
                businessNature:  this.show.corpDetails.corpInfo.businessNature,
                dateEstablish: this.formatDate(this.show.corpDetails.corpInfo.dateEstablish),
                salesPerMonth:  this.show.corpDetails.corpInfo.salesPerMonth,
                txnPerMonth:  this.show.corpDetails.corpInfo.txnPerMonth,
                grossIncomeAnnual:  this.show.corpDetails.corpInfo.grossIncomeAnnual,
                contactPerson:  this.show.corpDetails.corpInfo.contactPerson,
                contactEmailAddress:  this.show.corpDetails.corpInfo.contactEmailAddress,
                contactPhoneNumber:  this.show.corpDetails.corpInfo.contactPhoneNumber,
                bankAccountNumber:  this.show.corpDetails.corpInfo.bankAccountNumber,
                bankName: this.show.corpDetails.corpInfo.bankName,
                bankAccountName:  this.show.corpDetails.corpInfo.bankAccountName,
                tradingName: this.show.corpDetails.corpInfo.tradingName,
                "tradingAddress.numberStreet": this.show.corpDetails.tradingAddress.numberStreet,
                "tradingAddress.cityTown": this.show.corpDetails.tradingAddress.cityTown,
                "tradingAddress.provState": this.show.corpDetails.tradingAddress.provState,
                "tradingAddress.country": this.show.corpDetails.tradingAddress.country,
                "tradingAddress.postalCode": this.show.corpDetails.tradingAddress.postalCode,
              }

              const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

                const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/ax/profile/corp/step2/update/' + this.$route.params.entityId
                          };
                  this.axios(options)
                      .then((response) => { 
                     
                          this.isSubmittingLicenseType = false;
                          this.isEditingLicenseType = false; 
                          this.errors.profile.show = true;
                          setTimeout(()=>{
                            this.getProfile();
                              this.errors.profile.show = false;
                              this.errors.profile.msg = "";
                            }, 2000)

                      }).catch((err) => { 
                          this.isSubmittingLicenseType = true;
                          this.isEditingLicenseType = true; 
                          if(err.response.data.fieldErrors){
                            this.errors.profile.show = true;
                            this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage
                            setTimeout(()=>{
                            this.errors.profile.show = false;
                            this.errors.profile.msg = "";
                          }, 2000)
                        }
                        else {
                        
                            this.errors.profile.show = true;
                            this.errors.profile.msg = err.response.data.msgText
                            setTimeout(()=>{
                            this.errors.profile.show = false;
                            this.errors.profile.msg = "";
                            // window.location.reload();
                          }, 2000)
                          }

                  })
              
          
          
        },
       getIssuedToken(){
        const params = {
            entityId: this.$route.params.entityId
            }
           
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/businessfile/token/list' 
              }
              this.axios(options)
              .then((response) => {
                console.log(response.data)
                this.init.tokens = response.data
                this.init.numberOfTokens = response.data.length
                this.isApprovedStep1 = false
                if(this.init.tokens.length == 1){
                  this.isApprovedStep1 = true
                }
                this.isApprovedStep2 = false
                if(this.init.tokens.length == 2){
                  this.isApprovedStep2 = true
                }
                this.isApprovedStep3 = false
                if(this.init.tokens.length == 3){
                  this.isApprovedStep3 = true
                }
                this.isApprovedStep4 = false
                if(this.init.tokens.length == 4){
                  this.isApprovedStep4 = true
                }
                this.isApprovedStep5 = false
                if(this.init.tokens.length == 5){
                  this.isApprovedStep5 = true
                }
                this.isApprovedStep6 = false
                if(this.init.tokens.length == 6){
                  this.isApprovedStep6 = true
                }
                this.isApprovedStep7 = false
                if(this.init.tokens.length == 7){
                  this.isApprovedStep7 = true
                }
                this.isApprovedStep8 = false
                if(this.init.tokens.length == 8){
                  this.isApprovedStep8 = true
                }
              })
       },
        getBusinessFile(){
          const params = {
            entityId: this.$route.params.entityId
            }
           
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/customer/businessfile/' 
              }
              this.axios(options)
              .then((response) => {
                this.init.list.documentData = response.data

               
              }).catch((err) => {
                  
              })
        },
       
        viewUpload(imageFile){
          this.loiFile = imageFile
          this.showUpload = !this.showUpload
          
        },
        viewUploadBplan(imageFile){
          this.busPlanFile = imageFile
          this.showUploadBPlan = !this.showUploadBPlan
          
        },
        
        goToApplication(){
          window.location.href="/#/customer-application/" + this.$route.params.entityId
        },
        goToPayment(){
          window.location.href="/#/customer-payments/" + this.$route.params.entityId
        },
        
       
       
       
        openChangePin(){
          this.showChangePin = !this.showChangePin
        },
          goBack(){
            this.$router.go(-1);
        },
        formatDate: function(date) {
        return moment(new Date(date)).format('MM/DD/YYYY');
        },
          goToVerification(){
            window.location.href="/#/verification"
          },
         
          openUpload(){
              this.showUpload = !this.showUpload
          },
          getProfile() {
            this.isLoading = true;
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/getCustomerProfile',
              }
              this.axios(options)
              .then((response) => {
                this.isLoading = false;
              this.show.profile = response.data;
              this.show.corpDetails = response.data.corpDetails
              console.log(this.show.corpDetails)
                if( this.show.corpDetails.corpInfo != null || this.show.corpDetails.corpInfo != undefined || this.show.corpDetails.corpInfo != ''){
                  this.show.corpDetails.corpInfo = response.data.corpDetails.corpInfo
                }

              }).catch((err) => {
                  
              })
          },
       

          viewPoid(){
              this.showPoid = !this.showPoid;
          },
          getPoidType(){
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/ax/poids',
          }

          this.axios(options)
              .then((response) => {               
             this.poid = response.data
             console.log(response.data)
              }).catch((err) => {
              console.log(err);

              })

          },
          toggleCountryList: function(){
              this.showCountry = !this.showCountry;
              
              },
          
          selectCountry: function(c) {
              this.showCountry = false;
              this.country = c.countryCode;
              this.mobileCode = c.mobileCode;
          },
              
          getMobileCodes: function() {
          this.axios.get('/assets/js/mobilecodes.json')
              .then((response) => {
              this.list.mobileCodes = response.data;
              }).catch((err) => {
              console.log(err);

              })
          },
          isNumber: function(evt) {
              evt = (evt) ? evt : window.event;
              var charCode = (evt.which) ? evt.which : evt.keyCode;
              if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
              evt.preventDefault();
              } else {
              return true;
              }
          },
       
        
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
        this.axios(options)
              .then((response) => {
              
              this.getProfile();
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getBusinessFile();
          this.getIssuedToken();
      }
  }
</script>
        
<style scoped lang="scss">
    * {
    box-sizing: border-box;
  }
  .right{
    float: right !important;
  }
  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
    padding: 10px;
  
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
  .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      display: block;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: transparent;
          td {
            color: #FFFFFF;
            // letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
  .checkbox label{
    font-size: 12px !important;
  }
.header-wrapper img{
    width: 10em;
  }
  .loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
  .coins{
    margin: 10px;
    text-align: center !important;
    display: flex;
    justify-content: center;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }
  .placeholder-circle {
    width: 25px;
    height: 25px;
    border: 1px solid #ccc; /* Gray background */
    border-radius: 50%; /* Makes it a circle */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
  }
  .approved-circle{
    width: 25px;
    height: 25px;
    background-color: #c69903; /* Gray background */
    border-radius: 50%; /* Makes it a circle */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
    
  }
  .content-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    justify-content: center !important;
    /* max-width: 1000px; */
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #053d5c;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
    
  }
  .box-header{
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  li .active {
    border: 1px solid #07b254 !important;
  }
 
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   /* .mobile-container {
    position: relative;
   } */
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
  margin-bottom: 10px;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}




   

.icon img{
  width: 2rem;
}
.card{
  background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
  );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
}


.verified{
  background-color: green;
  color: white;
}
.submitted{
  background-color: green !important;
  color: white;
}
.notSubmitted{
  background-color: red;
  
}
  
 
  
    .progress-bars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    /* margin-bottom: 20px; */
  }
  
  .progress-bar {
    width: 100px;
    height: 50px;
    background-color: lightgray;
    background-color: #053d5c !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
  }
  
  .active {
    background-color: #053d5c !important;
    color: white !important;
  }

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }

  .progress-bar {
    width: 80px;
    height: 50px;
    background-color: lightgray;
    background-color: #053d5c !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
    
  }
  
}
@media (max-width: 1280px) {

  
}
  
  label {
    margin-right: 5px;
  }
  

.btn-card-upload{
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #053d5c !important;
  display: flex;
  justify-content: space-between;
 
}



.indicator img{
  width: 1rem;
}
.indicator-progress img{
  width: 1rem;
}

</style>
