<template>
    <div class="backdrop">
      <div class="b-modal">
          <div class="modal-header text-right">
              <h5 class="m-2 text-white">Confirm</h5>
              <p @click="closeModal" class="m-2 x">x</p>
          </div>
            <div class="text-center">
                  
                        <h5 v-if="show.profile.status == 'ACTIVE'">Are you sure you want to disable this account?</h5>
                        <h5 v-if="show.profile.status == 'CLOSED'">Are you sure you want to reactivate this account?</h5>
		   			
                       <div class="buttonWrapper">
                            <button class="btn btn-dark" v-on:click="toggleEnable" v-if="show.profile.status == 'CLOSED'"> Confirm Enable</button>
                            <button class="btn btn-dark" v-on:click="toggleDisable" v-if="show.profile.status == 'ACTIVE'"> Confirm Disable</button>
                            <button class="btn btn-light" v-on:click="closeModal"> Cancel </button>
                       </div>
                      
         
        	
            </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
      components: {
  
      },
      data: function() {
          return {
              
          }
      },
  
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        

      },
  
      mounted() {
       
      },
  
      watch: {
        
      },
  
      methods: {
          closeModal(){
             window.location.reload();
          },
          toggleEnable(){
          const params = {
            eid: this.$route.params.entityId,
            isActive: true,
          }
          const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            data,
            url: '/ax/updateCustomerStatus',
          }

          this.axios(options)
              .then((response) => {               
             window.location.reload();
             console.log(response.data)
              }).catch((err) => {
              console.log(err);

              })
        },
        toggleDisable(){
          const params = {
            eid: this.$route.params.entityId,
            isActive: false,
          }
          const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            data,
            url: '/ax/updateCustomerStatus',
          }

          this.axios(options)
              .then((response) => {               
             window.location.reload();
             console.log(response.data)
              }).catch((err) => {
              console.log(err);

              })
        },
       
        
       
      },
      beforeMount(){
      },
      
  }
  </script>
  
  <style scoped>
  .backdrop {
      top: 0;
      right:0;
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 98;
      background: rgba(0, 0, 0, 0.6);
  
  }
  .b-modal {
      width: 700px;
      height: auto;
      margin: 175px auto;
      background: white;
      border-radius: 20px;
      border-color:white;
      border-width:2px;
      z-index: 99;
      overflow-x: hidden !important;
      color:black;
  }
  
  .modal-header {
      background-color: white;
      margin-right:0px;
      font-size:25px;
      display:flex;
      justify-content:space-between;
  }
  
  .modal-title{
      margin: 0 auto;
      color:white;
  }
  
  .modalBody {
      background-color:white;
      width:100%;
      padding:50px;
      border-radius: 10px;;
  }
  
  .buttonWrapper {
      display: flex;
      justify-content: center;
      padding: 10px;
  }
  .btn, .btn-dark, .btn-light{
    border-radius: 6px !important;
  }
  .x {
      cursor:pointer;
  }
 
  </style>